import Vue from 'vue'
import App from './App.vue'

import router from './router'
// import "@/assets/text.css";

// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.




/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })

<template>
  <div>


    <div class="head">
      <div class="logo pc">
        <a href="#">
          <img src="images/logo.png" alt="">
        </a>
      </div>
      <div class="wap wapswitch"></div>
      <div class="nav sideDot">
        <ul>
          <li>
            <h2 class="h2tit">
              <a href="#">About</a>
            </h2>
          </li>
          <li>
            <h2 class="h2tit">
              <a href="#">mint</a>
            </h2>
          </li>
          <li>
            <h2 class="h2tit">
              <a href="#">Features</a>
            </h2>
          </li>
          <li>
            <h2 class="h2tit">
              <a href="#">Whitepaper</a>
            </h2>
          </li>
          <li>
            <h2 class="h2tit">
              <a href="#">Tokenomics</a>
            </h2>
          </li>
          <li>
            <h2 class="h2tit">
              <a href="#">Roadmap</a>
            </h2>
          </li>
        </ul>
      </div>
      <div class="right">
        <a href="javascript:;" class="ftm" @click="onClickFtm">{{ balance }} FTM</a>
        <a href="javascript:;" class="connect" @click="connect_wallet">{{ showName() }}</a>
      </div>
    </div>


    <div class="sideMenu">
      <div class="switch"></div>
      <ul class="sideDot2">
        <li class="">
          <h2 class="h2tit">
            <a href="#">
              <span class="icon">
                <img src="images/icon1.png" alt="">
                <img src="images/iconde1.png" class="curs" alt="">
              </span>
              <span class="tit">Home</span>
            </a>
          </h2>
        </li>
        <li>
          <h2 class="h2tit">
            <a href="#">
              <span class="icon">
                <img src="images/icon2.png" alt="">
                <img src="images/iconde2.png" class="curs" alt="">
              </span>
              <span class="tit">Launchpad List</span>
            </a>
          </h2>
        </li>
        <li>
          <h2 class="h2tit">
            <a href="#">
              <span class="icon">
                <img src="images/icon3.png" alt="">
                <img src="images/iconde3.png" class="curs" alt="">
              </span>
              <span class="tit">Create Presale</span>
            </a>
          </h2>
        </li>
        <li>
          <h2 class="h2tit">
            <a href="#">
              <span class="icon">
                <img src="images/icon4.png" alt="">
                <img src="images/iconde4.png" class="curs" alt="">
              </span>
              <span class="tit">Create Token</span>
            </a>
          </h2>
        </li>
        <li>
          <h2 class="h2tit">
            <a href="#">
              <span class="icon">
                <img src="images/icon5.png" alt="">
                <img src="images/iconde5.png" class="curs" alt="">
              </span>
              <span class="tit">My Launchpad</span>
            </a>
          </h2>
        </li>
        <li>
          <h2 class="h2tit">
            <a href="#">
              <span class="icon">
                <img src="images/icon6.png" alt="">
                <img src="images/iconde6.png" class="curs" alt="">
              </span>
              <span class="tit">KYC & Audit</span>
            </a>
          </h2>
        </li>
      </ul>
      <div class="link">
        <a href="https://x.com/sonic_pad" target="_blank">
          <img src="images/li1.png" alt="">
        </a>
        <a href="https://t.me/Sonic_pad" target="_blank">
          <img src="images/li2.png" alt="">
        </a>
        <a href="https://discord.gg/s5Z3BKbS3H" target="_blank">
          <img src="images/li3.png" alt="">
        </a>
      </div>
    </div>


    <div class="index">
      <div class="stars">
        <i></i>
        <i></i>
        <i></i>
        <i></i>
        <i></i>
      </div>
      <div class="shadow">
        <img src="images/shadow1.png" alt="">
      </div>
      <div class="shadow2">
        <img src="images/shadow2.png" alt="">
      </div>
      <div class="shadow3">
        <img src="images/shadow3.png" alt="">
      </div>
      <div class="shadow4">
        <img src="images/shadow4.png" alt="">
      </div>
      <div class="section1">
        <div class="ani2">
          <img src="images/ani2.png" alt="">
        </div>
        <div class="ani">
          <img src="images/vg.png" alt="">
        </div>
        <div class="wrap">
          <div class="title">
            Sonic Launchpad on the Sonic chain
            <div class="lid1"><i></i></div>
          </div>
          <div class="dec">Our main function is to incubate and support innovative blockchain projects by providing them
            with the necessary resources and exposure in the Sonicpad ecosystem.
          </div>
        </div>
      </div>
      <div class="section2 scitem">
        <div class="wrap">
          <div class="titles">Sonicpad OG Passcard</div>
          <div class="content1">
            <div class="imgbox">
              <img src="images/img1.png" alt="">
            </div>
            <div class="right">
              <div class="title">
                Sonicpad OG Passcard
              </div>
              <div class="dec1">
                <p>
                  quantity： <b>10000/10000</b>
                </p>
                <p>
                  Mint Price： <b>FREE</b>
                </p>
                <p>
                  Eligibility check time： <b>2024.07.04</b>
                </p>
              </div>
              <div class="dec2">
                <p>
                  1. Sonic NFTs holders can participate on exclusive whitelisted projects.
                </p>
                <p>
                  2. Random NFT holders will be airdropped projects depending on partnerships with the respective
                  project.
                </p>
                <p>
                  3. You can trade NFTs on marketplace.
                </p>
                <p>
                  4. Sonic NFT holders will have many perks than normal investors.
                </p>
              </div>
            </div>
          </div>
          <div class="content2">
            <div class="title">Eligibility check Starts in</div>


            <div class="timer" id="dateShow1">
              <div class="item date-tiem-span d val">00</div>
              <span>:</span>
              <div class="item date-tiem-span h val">00</div>
              <span>:</span>
              <div class="item date-tiem-span m val">00</div>
              <span>:</span>
              <div class="item date-tiem-span s val">00</div>
            </div>


            <div class="line">
              <i :style=progressStyle>
                <img src="images/rocket.png" alt="">
              </i>
            </div>
            <div class="vals">
              <p>Progress</p>
              <p>{{ settings.progress }}%</p>
            </div>
            <div class="vals" style="justify-content:center">
              A single address can mint up to one Sonicpad OG Passcards.
            </div>
          </div>
          <div class="content3">
            <!--            <div class="item">-->
            <!--              <div class="title">Get 100 Points</div>-->
            <!--              <div class="model">-->
            <!--                <div class="icon">-->
            <!--                  <img src="images/li1.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="tit">Follow @sonic_pad on twitter</div>-->
            <!--                <div v-if="!user.task1" class="more m1 bounce3" @click="DoTask(1)"></div>-->
            <!--                <div v-if="user.task1" class="more m1 done2"></div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="item">-->
            <!--              <div class="title">Get 100 Points</div>-->
            <!--              <div class="model">-->
            <!--                <div class="icon">-->
            <!--                  <img src="images/li2.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="tit">Have the Verified role in Sonicpad Community Discord Server</div>-->
            <!--                &lt;!&ndash;                <div class="more m1 done"></div>&ndash;&gt;-->
            <!--                <div v-if="!user.task2" class="more m1 bounce3" @click="DoTask(2)"></div>-->
            <!--                <div v-if="user.task2" class="more m1 done2"></div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="item">-->
            <!--              <div class="title">Get 100 Points</div>-->
            <!--              <div class="model">-->
            <!--                <div class="icon">-->
            <!--                  <img src="images/li3.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="tit">Link Telegram Account</div>-->
            <!--                <div v-if="!user.task3" class="more m1 bounce3" @click="DoTask(3)"></div>-->
            <!--                <div v-if="user.task3" class="more m1 done2"></div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="item">-->
            <!--              <div class="title">Get 100 Points</div>-->
            <!--              <div class="model">-->
            <!--                <div class="icon">-->
            <!--                  <img src="images/li3.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="tit">Link Telegram Account</div>-->
            <!--                <div class="more m1"></div>-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="item2" v-if="wallet_address">
              <input type="text" v-model=wallet_address class="words">
              <!--              <input type="button" value="check" class="send">-->
              <input type="button" value="Mint" class="send" @click="mint"
              >
              <!--              v-if="user.task1 && user.task2 && user.task3"-->
            </div>
            <!--            <div class="item3" v-if="user.task1 && user.task2 && user.task3">-->
            <!--              <div class="icon">-->
            <!--                <img src="images/iconcurr.png" alt="">-->
            <!--                <div class="tits">winner</div>-->
            <!--              </div>-->
            <!--              <div class="left">-->
            <!--                <div class="tit">🎉 congratulations！</div>-->
            <!--                <button class="btn">Whitelist</button>-->
            <!--              </div>-->
            <!--              <div class="ris">-->
            <!--                300 Points-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="item2">-->
            <!--              <input type="button" value="Mint" class="send" @click="mint">-->
            <!--            </div>-->
          </div>
        </div>
      </div>
      <div class="section3 scitem">
        <div class="wrap2">
          <div class="left">
            <div class="title">Why Choose Sonicpad</div>
            <div class="dec">
              <p>
                1.Token Offerings: Facilitating token sales for early-stage projects, enabling them to raise funds from
                the community in a fair and transparent manner.
              </p>
              <p>
                2.Community Engagement: Fostering an active and supportive community around new projects, encouraging
                participation and feedback from token holders.
              </p>
              <p>
                3.Strategic Partnerships: Collaborating with industry leaders and experts to provide projects with
                valuable guidance and resources.
              </p>
              <p>
                4.Security and Trust: Implementing robust protocols to ensure the integrity and security of fundraising
                activities, protecting both investors and project teams.
              </p>
              <p>
                5.Marketing Support : The core team members will help any upcoming project on launchpad with all sort of
                marketing needs.
              </p>
              <p>
                6.Security Badges : Sonicpad will provide high level audit and KYC service to projects, which will
                ensure security for our investors. Our Badges will include KYC, Audit , Doxxed and Vetted.
              </p>
            </div>
          </div>
          <div class="right">
            <img src="images/circle.png" class="clr" alt="">
          </div>
        </div>
      </div>
      <div class="section4 scitem">
        <div class="wrap2">
          <div class="title">What Makes Sonicpad Special?</div>
          <div class="list">

            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="item">
                    <div class="icon">
                      <img src="images/ims1.png" alt="">
                    </div>
                    <div class="tit">Rigorous Project Vetting</div>
                    <div class="dec">Sonicpad ensures that only the best projects are presented to the public by
                      conducting thorough vetting of all potential projects.
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="item">
                    <div class="icon">
                      <img src="images/ims2.png" alt="">
                    </div>
                    <div class="tit">Refunds On Failed Launches</div>
                    <div class="dec">Sonicpad "Launch Refund" policy allows participants to receive a refund if a
                      project doesn't meet predetermined criteria. This protects early investors and incentivizes
                      projects to meet their commitments and goals.
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="item">
                    <div class="icon">
                      <img src="images/ims3.png" alt="">
                    </div>
                    <div class="tit">Guaranteed Allocations For $Sonic Holders</div>
                    <div class="dec">Holders of the $Sonic token will be granted a confirmed allocation for upcoming IDO
                      projects, with their ticket sizes determined by the quantity of staked $Sonic tokens they possess.
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="item">
                    <div class="icon">
                      <img src="images/ims4.png" alt="">
                    </div>
                    <div class="tit">Earn passive income by staking your $Sonic</div>
                    <div class="dec">Earn more $Sonic tokens by staking and farming on our staking portal. As a holder,
                      you can also benefit from our Seed Staking Feature, which allows you to receive free tokens from
                      our incubated projects.
                    </div>
                  </div>
                </div>
              </div>
              <div class="wap">
                <div class="swiper-pagination"></div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="section5 scitem">
        <div class="wrap2">
          <div class="ani">
            <img src="images/dq.png" alt="">
          </div>
          <div class="title">Utility Token</div>
          <div class="dec">
            The Sonicpad Utility Token, also known as $Sonic, is an integral part of the Sonicpad ecosystem. Holders of
            $Sonic can enjoy exclusive benefits such as receiving guaranteed allocations for token sales of the projects
            launched on our launchpad. Additionally, by staking their $Sonic tokens, holders can earn interest and
            obtain exclusive loyalty rewards for participating in Initial DEX Offerings (IDOs) on the platform. The more
            $Sonic tokens staked, the greater the rewards earned.
          </div>
          <div class="list">
            <div class="item">
              <div class="tit">TOKEN NAME</div>
              <div class="val">SPT</div>
            </div>
            <div class="item">
              <div class="tit">SYMBOL</div>
              <div class="val">$Sonic</div>
            </div>
            <div class="item">
              <div class="tit">MAX SUPPLY</div>
              <div class="val">20,000,000</div>
            </div>
            <div class="item">
              <div class="tit">BLOCKCHAIN</div>
              <div class="val">Sonic Chian</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section6 scitem">
        <div class="wrap2">
          <div class="title">Roadmap</div>
          <div class="list">

            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="item">
                    <div class="tit">Q3 2024</div>
                    <div class="dec">
                      <p>Reveal Socials</p>
                      <p>IDO Announcement for Sonicpad</p>
                      <p>Release launchpad </p>
                      <p>Marketing Sonicpad through Top Tier Influencers</p>
                      <p>Banner Ads for IDO on various platforms</p>
                      <p>Token Launch</p>
                      <p>CMC/CG Listings</p>
                      <p>Sonicpad Genesis NFT Mint</p>
                    </div>
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="item">
                    <div class="tit">Q4 2024</div>
                    <div class="dec">
                      <p>Marketing Launchpad along with listing of Top Tier Projects</p>
                      <p>Introduce Buyback and Burn Program</p>
                      <p>Partnerships with Top Tier Projects</p>
                      <p>Launch LP Locker for Sonicpad</p>
                      <p>Tier 1/2 CEX Listings</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="wap">
                <div class="swiper-pagination"></div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="section7 scitem">
        <div class="icon">
          <img src="images/logo2.png" alt="">
        </div>
        <div class="tit">
          Are you a Project Founder?
          <div class="lid1"></div>
        </div>
        <div class="dec">Apply For IDO On Sonicpad</div>
        <a href="#" class="more">apply here</a>
      </div>
    </div>

    <div class="footer">
      <a href="https://x.com/sonic_pad" target="_blank">
        <img src="images/li1.png" alt="">
      </a>
      <a href="https://t.me/Sonic_pad" target="_blank">
        <img src="images/li2.png" alt="">
      </a>
      <a href="https://discord.gg/s5Z3BKbS3H" target="_blank">
        <img src="images/li3.png" alt="">
      </a>
    </div>


  </div>
</template>

<script>
import {ethers} from "ethers";
import axios from "axios";
import {contract_abi} from "@/pages/abi";
// axios.defaults.withCredentials = true
// import Decimal from "decimal.js";

export default {
  name: "App",
  components: {},
  data() {
    return {
      settings: {
        progress: 0,
      },
      modelInvisiable: false,
      inputValue: "",
      wallet_address: "",
      provider: null,
      singer: null,
      contract: null,
      domain: "",
      domain_status: 'Available',
      price: "0",
      duration: 3,
      stringDomain: "",
      points: 0,
      queryString: window.location.search,
      queryParams: {},
      inviter_address: "0x0000000000000000000000000000000000000000",
      copyText: "",
      msg: "",
      balance: 0,
      user: {
        "id": null,
        "task1": false,
        "task2": false,
        "task3": false,
      },
      contract_address: "0xa47E1eA21D5a36e8b4526Dfdb8deA980bCB34Aa8",
      contract_abi: contract_abi,
    }
  },
  computed: {
    progressStyle() {
      return "width:" + this.settings.progress + "%";
    }
  },
  mounted() {
    const inviter = this.$route.query.inviter;
    if (inviter != undefined) {
      this.inviter_address = inviter;
    }
    console.log("mounted inviter", inviter);
    this.loadSetting();
  },
  methods: {
    clickCopy() {
      var that = this;
      const save = function (e) {
        e.clipboardData.setData('text/plain', that.copyText)
        e.preventDefault() // 阻止默认行为
      }
      document.addEventListener('copy', save) // 添加一个copy事件
      document.execCommand('copy') // 执行copy方法
      // this.$message({ message: '复制成功', type: 'success' })
      that.msg = "Copy Success"
    },
    showName() {
      if (this.wallet_address != "") {
        return this.wallet_address.substring(0, 6) + '...' + this.wallet_address.substring(this.wallet_address.length - 4, this.wallet_address.length)
      }
      return "Connect Wallet"
    },
    async addCustomRPC() {
      try {
        await window.ethereum.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: '0xfa', // 16 进制表示的链 ID
              chainName: 'Fantom Opera',
              nativeCurrency: {
                name: 'FTM',
                symbol: 'FTM',
                decimals: 18
              },
              rpcUrls: ['https://rpcapi.fantom.network'], // 你的 RPC 端点 URL
              blockExplorerUrls: ['https://ftmscan.com'] // 可选的区块浏览器 URL
            }
          ]
        });
        console.log('RPC 端点添加成功!');
      } catch (error) {
        console.error('添加 RPC 端点失败:', error);
      }
    },
    async switchToCustomRPC() {
      try {
        // 请求用户授权访问其钱包
        await window.ethereum.request({method: 'eth_requestAccounts'});

        // 切换到新的 RPC 端点
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{chainId: '0xfa'}] // 16 进制表示的链 ID
        });

        console.log('已切换到自定义 RPC 端点');
      } catch (error) {
        console.error('切换 RPC 端点失败:', error);
      }
    },
    async onClickFtm() {
      await this.addCustomRPC();
      await this.switchToCustomRPC();
    },
    async connect_wallet() {
      // if (this.wallet_address != null) {
      //   return this.disconnect_wallet();
      // }
      // const a = await connect();
      // console.log(a.account);
      // this.wallet_address = a.account.address;
      // console.log(this.wallet_address)
      // this.provider = a.provider;
      // this.account = a.account;
      //链接metadata钱包

      if (this.wallet_address) {
        await this.disconnect_wallet();
        return;
      }

      if (typeof window.ethereum === 'undefined' && typeof window.web3 === 'undefined') {
        alert('Wallet not installed!');
        return;
      }

      console.log('Wallet installed');

      // 创建一个 ethers.providers.JsonRpcProvider 对象。
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // 检查是否连接钱包，如果未连接则提示用户连接钱包。
      let accounts = await window.ethereum.request({method: 'eth_requestAccounts'});

      // 使用账户地址获取签名，这样我们就可以发送交易了
      const signer = provider.getSigner(accounts[0]);
      console.log(signer);

      const wallet_address = await signer.getAddress();
      let chain = await provider.getNetwork();
      let code = chain.chainId;
      console.log("code", code)
      if (code === 150) {
        console.log("This wallet is on ftm network");
      } else {
        await this.switchToCustomRPC();
      }

      console.log("wallet_address", wallet_address)

      // 签名消息
      const message = "login sonicpad";
      const signature = await signer.signMessage(message);

      let resp = await axios.post("https://sonicpad.xyz/api/Login", {
            id: wallet_address,
            signature: signature
          },
          {
            // withCredentials: true
          });

      this.wallet_address = wallet_address;
      this.provider = provider;
      this.signer = signer;
      this.user = resp.data.user;
      // await axios.get("https://sonicpad.xyz/api/", {
      //   withCredentials: true
      // });
      // await axios.get("https://sonicpad.xyz/api/");
      // await axios.get("https://sonicpad.xyz/api/");

      this.contract = new ethers.Contract(this.contract_address, this.contract_abi, this.signer);

      // 获取当前账户的 ETH 余额
      const balance = await this.provider.getBalance(wallet_address);

// 将余额转换为 ETH 单位并四舍五入到两位小数
      const formattedBalance = ethers.utils.formatEther(balance).slice(0, 5);
      console.log("formattedBalance", formattedBalance)
      this.balance = formattedBalance;

    },
    async DoTask(task_id) {

      switch (task_id) {
        case 1:
          window.open("https://x.com/sonic_pad", "_blank");
          break;
        case 2:
          window.open("https://discord.gg/s5Z3BKbS3H", "_blank");
          break;
        case 3:
          window.open("https://t.me/Sonic_pad", "_blank");
          break;
      }

      let resp = await axios.post("https://sonicpad.xyz/api/FinishTask", {
        task_id: task_id
      });
      this.user = resp.data.user;
    },
    async disconnect_wallet() {
      console.log("disconnect");
      // await disconnect();
      this.balance = 0;
      this.wallet_address = "";
      this.provider = null;
      this.signer = null;
      this.user = {
        "id": null,
        "task1": false,
        "task2": false,
        "task3": false,
      }
    },
    getQueryParam: function (keyname) {
      if (!this.queryParams[keyname]) {
        const query = decodeURIComponent(this.queryString).substr(1);
        console.log("query", query);
        const queryParams = query.split("&");
        queryParams.forEach(param => {
          const key = param.split("=")[0];
          const value = param.split("=")[1];
          this.queryParams[key] = value;
        });
      }
      return this.queryParams[keyname];
    },
    async mint() {
      // 计算铸造所需的ETH数量
      const value = ethers.utils.parseEther("100");
      let resp;
      try {
        resp = await axios.post("https://sonicpad.xyz/api/signature", {
          address: this.wallet_address
        })
      } catch (error) {
        alert(error.response.data.error);
        console.error('Error minting ERC721 token:', error)
      }

      try {
        // 调用合约的mint方法,并传入价格
        let sig = resp.data.signature;
        console.log(this.contract, value.toString(), sig);
        let tx = await this.contract.mint(sig, {
          // value: ethers.utils.parseEther("100"),
          gasLimit: 300000,
        }).send();

        alert("Congratulations 🎉 for getting sonicpad OGpasscard*1");

        console.log('ERC721 token minted successfully!', tx)
      } catch (error) {
        console.log('Error minting ERC721 token:', error)
        // alert(error.reason);
      }
    },
    async loadSetting() {
      let resp = await axios.get("https://sonicpad.xyz/api/settings");
      let s = resp.data.settings;
      for (let i = 0; i < s.length; i++) {
        let c = s[i];
        this.settings[c['id']] = c['value'];
      }
      console.log("settings", this.settings);
      // = resp.data.settings;
    }
  }
}
</script>

<style scoped>

</style>
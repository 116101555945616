import Vue from 'vue'
import Router from 'vue-router'
// import IndexPage from '@/pages/Index.vue'
import TestPage from '@/pages/Test.vue'
import AppPage from '@/pages/App.vue'
Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'index',
            component: AppPage
        },
        {
            path: '/app',
            name: 'app',
            component: AppPage
        },
        {
            path: '/test',
            name: 'test',
            component: TestPage
        }
    ]
})
